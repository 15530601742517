<template>
  <div class="confirm-mail-div margin-left p-0">
    <div class="login-topbar px-4 py-2">
      <b-row class="new-row">
        <b-col cols="6">
          <img src="/img/bludots.svg " alt />
        </b-col>
        <b-col cols="6" class="text-right">
          <div class="item">
            <a href="#" @click.prevent="cancelStep">
              <span class="icon">
                <v-icon :icon="['fac', 'close']" />
              </span>
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="container-fluid">
      <loading :loading="loading" />
      <div
        class="row justify-content-center align-items-center zoom"
        v-show="!loading"
        style="height: 100vh !important"
      >
        <div class="col-lg-10 col-sm-10 text-left confirm-mail-height">
          <!-- <img
            src="/img/confirm-logos.svg"
            class="img-fluid confirm-logo"
            alt=""
          /> -->
          <h1  class="text-left ">{{$t("auth.confirm.title")}}</h1>

          <ul style="list-style-type: none !important">
            <li>
              <img src="/img/list-style-circle.svg" class="img-fluid" alt="" />
              {{$t("auth.confirm.itemline1")}}
            </li>
            <li>
              <img src="/img/list-style-circle.svg" class="img-fluid" alt="" />
               {{$t("auth.confirm.itemline2")}}
            </li>
            <li>
              <img src="/img/list-style-circle.svg" class="img-fluid" alt="" />
               {{$t("auth.confirm.itemline3")}}
            </li>
          </ul>

          <hr>

          <!-- did not receive email -->

          <p href="#">
            <img src="/img/account-icons.svg" class="img-fluid mr-3" alt="" />
             {{$t("auth.confirm.help_item")}}
            <!-- <a @click="formSubmit()">{{$t("auth.confirm.help_item_link")}}.</a> -->
          </p>

  <ul style="list-style-type: none !important">
            <li>
              <img src="/img/list-style-circle.svg" class="img-fluid" alt="" />
              {{$t("auth.confirm.itemline1-nr")}}
            </li>
            <li>
              <img src="/img/list-style-circle.svg" class="img-fluid" alt="" />
               {{$t("auth.confirm.itemline2-nr")}}
            </li>
            <li>
              <img src="/img/list-style-circle.svg" class="img-fluid" alt="" />
               {{$t("auth.confirm.itemline3-nr")}}
            </li>
          </ul>
           <p href="#">
              <img src="/img/account-icons.svg" class="img-fluid mr-3" alt="" />
              {{$t("auth.confirm.help_item_link-nr")}}
              
 <b-button class="resend-btn" @click="formSubmit()">{{$t("auth.confirm.help_item_link")}}.</b-button>
  </p>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '../../../components/global/loading/index';
import { mapGetters } from 'vuex';
import resendConfirmMail from '@/graphql/auth/resendConfirmMail.gql';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getEmail: 'general/getEmail',
      getStep: 'general/getCompleteStep',
    }),
  },
  methods: {
    async formSubmit() {
      try {
        this.loading = true;

        let variable = {
          email: this.getEmail,
        };
        let data = await this.$apollo.mutate(resendConfirmMail, variable);
        let response = data('**.sendConfirmEmail');

       return this.$bvToast.toast(this.$t("Toaster.resent-mail"), {
          title: this.$t("Toaster.success-title"),
          variant: "success",
          autoHideDelay: 1350,
        });
      } catch (e) {
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
